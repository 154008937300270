function index() {
  return (
    <section className="container como-comprar">
      <h2>Como comprar</h2>
      <p>
        Para adquirir um produto, basta escolher em nossa vitrine de produtos. O
        processo é feito 100% de forma online e garantimos comodidade e
        segurança com seus dados.
      </p>
      <a href="https://www.decathlon.com.br/circula/produtos#1 ">
        <span>VER TODOS OS PRODUTOS</span>
      </a>
    </section>
  )
}

export default index
