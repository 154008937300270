function index() {
  return (
    <>
      <section className="container regulamento">
        <div className="card-regulamento">
          <h2>Regulamento</h2>
          <p>Confira aqui o regulamento completo do Decathlon Circula.</p>
          <a
            className="link-regulamento"
            href="https://www.decathlon.com.br/servicos/decathlon-circula"
          >
            Confira o regulamento
          </a>
        </div>
        <a className="contact" href="https://www.decathlon.com.br/atendimento">
          Central de atendimento
        </a>
      </section>
    </>
  )
}

export default index
