import scrollTo from 'gatsby-plugin-smoothscroll'
import { useRef } from 'react'

function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollEasyMobile = useRef<HTMLHeadingElement | null>(null)

  function handleScroll(): void {
    if (window.screen.width > 991) {
      scrollTo('#scroll-easy', 'center')
    } else {
      scrollEasyMobile?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }

  return (
    <>
      <section className="container container-top">
        <div className="desktop">
          <div className="banners desktop">
            <div className="card-img1-img2">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-trilha.png"
                alt="Trilha Circula Decathlon"
              />
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-bike.png"
                alt="Bike Circula Decathlon"
              />
            </div>
            <div className="img3">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-rockrider.png"
                alt="rockrider Decathlon"
              />
            </div>
          </div>
          <div className="containt-section-top">
            <div className="logo-decathlon">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/logo-lp-circula-decathlon.png"
                alt="logo-decathlon"
              />
            </div>
            <h1>Chegou o novo serviço da Decathlon!</h1>
            <p className="texto-informativo">
              O Decathlon Circula nasceu para você, esportista, que busca a
              oportunidade de vender ou comprar um produto esportivo usado, mas
              deseja <span> suporte e facilidade em todo o processo.</span>
            </p>
            <button onClick={() => handleScroll()} className="btn-saiba-mais">
              <span>Saiba mais</span>
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/icon-arrow-down.png"
                alt="icone seta para baixo"
              />
            </button>
          </div>
        </div>

        <div className="mobile">
          <div className="containt-section-top">
            <div className="logo-decathlon">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/logo-lp-circula-decathlon.png"
                alt="logo-decathlon"
              />
            </div>
            <h1>Chegou o novo serviço da Decathlon!</h1>
            <p className="texto-informativo">
              O Decathlon Circula nasceu para você, esportista, que busca a
              oportunidade de vender ou comprar um produto esportivo usado, mas
              deseja <span> suporte e facilidade em todo o processo.</span>
            </p>
            <button onClick={() => handleScroll()} className="btn-saiba-mais">
              <span>Saiba mais</span>
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/icon-arrow-down.png"
                alt="icone seta para baixo"
              />
            </button>
          </div>
          <div className="banners">
            <div className="flex-banners">
              <div className="card-img1-img2">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-trilha-mobile.png"
                  alt="Trilha Circula Decathlon"
                />
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-bike-mobile.png"
                  alt="Bike Circula Decathlon"
                />
              </div>
              <div className="img3">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-rockrider.png"
                  alt="Rockrider Circula Decathlon"
                />
              </div>
            </div>
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-banner-beach-tennis-mobile.png"
              alt="imagem-beach-tennis"
            />
          </div>
        </div>
      </section>
      <section className="container circula-easy">
        <div className="desktop" id="scroll-easy">
          <h2>Circular experiências ficou muito mais fácil!</h2>
          <div className="container-info">
            <div className="background-line" />
            <div className="card card-1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-card-1.png"
                alt="imagem-bola"
              />
              <p>
                Você escolhe o equipamento esportivo que deseja vender e o leva
                para um dos pontos de coleta nas lojas Decathlon Morumbi ou
                Marginal Tietê
              </p>
            </div>
            <div className="card card-2">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-card-2-new.png"
                alt="imagem-camera-fotografica"
              />
              <p>
                Nós recebemos o seu produto e realizamos a fotografia
                profissional, precificação e o cadastro no sistema.
              </p>
            </div>
            <div className="card card-3">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-card-3.png"
                alt="imagem-carrinho-compras"
              />
              <p>
                Pronto! Você será avisado quando o anúncio do seu produto
                estiver disponível nesta página. Quando o seu produto for
                vendido, você receberá a sua parte em conta!
              </p>
            </div>
          </div>
        </div>
        <div className="mobile">
          <h2>Circular experiências ficou muito mais fácil!</h2>
          <div className="container-info">
            <div className="background-line" />
            <div className="card card-1">
              <img
                className="img-card-1"
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-card-1.png"
                alt="imagem-bola"
              />
              <p>
                Você escolhe o equipamento esportivo que deseja vender e o leva
                para um dos pontos de coleta nas lojas Decathlon Morumbi ou
                Marginal Tietê
              </p>
            </div>
            <div ref={scrollEasyMobile} />
            <div className="card card-2">
              <img
                className="img-card-2"
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-card-2-new-mobile.png"
                alt="imagem-camera-fotografica"
              />
              <p>
                Nós recebemos o seu produto e realizamos a fotografia
                profissional, precificação e o cadastro no sistema.
              </p>
            </div>
            <div className="card card-3">
              <img
                className="img-card-3"
                src="https://decathlonpro.vteximg.com.br/arquivos/lp-circula-card-3.png"
                alt="imagem-carrinho-compras"
              />
              <p>
                Pronto! Você será avisado quando o anúncio do seu produto
                estiver disponível nesta página. Quando o seu produto for
                vendido, você receberá a sua parte em conta!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default index
