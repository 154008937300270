function index() {
  return (
    <>
      <section className="parceria">
        <div className="content__parceria">
          <h2>A Semexe é a nossa parceira!</h2>
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/logo-parceria.png"
            alt="logo-decathlon"
          />
          <p>
            Contamos com a parceria desta startup que cuida de toda a sua
            experiência de comprar e vender produtos usados. Eles cuidam da
            parte de coleta, curadoria dos produtos, avaliação, catalogação,
            precificação, logística e transporte dos produtos.
          </p>
        </div>
      </section>
    </>
  )
}

export default index
