import './style.scss'

interface ModalProps {
  hideModal: (open: boolean) => void
}

const ModalWhatsApp: React.FC<ModalProps> = ({ hideModal }) => {
  return (
    <section>
      <div className="av-modal av-modal--whatsapp">
        <div className="av-modal__modal">
          <div className="av-modal__header">
            <button
              aria-hidden="true"
              onClick={() => hideModal(false)}
              className="av-modal-close"
            />
          </div>
        </div>
        <div className="av-modal__content">
          <span>
            Escaneie o QR-CODE na <strong>câmera do seu celular</strong>
          </span>
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/qr-code-fale-com-especialista-whatsapp.png?v=637569444711530000"
            alt="QR CODE WHATSAPP"
          />

          <a
            href="https://api.whatsapp.com/send/?phone=5511947312606&text=Ol%C3%81%20%21,%20gostaria de falar com um especialista do esporte."
            target="_blank"
            rel="noreferrer"
          >
            ABRIR WHATSAPP
          </a>
          <br />
          <div className="av-wrap__span">
            <span>ou se preferir:</span>
            <span className="whats-app__number">55 11 94731-2606</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ModalWhatsApp
