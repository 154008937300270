function index() {
  return (
    <>
      <section className="container ponto-de-coleta-title">
        <h2 className="text-center mb-0 !pt-[100px]">Pontos de coleta</h2>
        <div className="sm:flex sm:items-center">
          <div className="container ponto-de-coleta !pt-0">
            <h3>DECATHLON MORUMBI</h3>
            <p>
              Av. Duquesa de Goiás, 381 - Real Parque, São Paulo - SP, 05686-001
            </p>
            <a href="https://www.decathlon.com.br/lojas/morumbi">
              IR ATÉ A LOJA
            </a>
          </div>
          <div className="container ponto-de-coleta !pt-0">
            <h3>DECATHLON MARGINAL TIETÊ</h3>
            <p>
              Av. Pres. Castelo Branco, 4885 - Pte. Pequena, São Paulo - SP,
              01142-300
            </p>
            <a href="https://www.decathlon.com.br/lojas/marginal-tiete">
              IR ATÉ A LOJA
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default index
