import { useCallback, useRef, useState } from 'react'

import './style.scss'

interface Props {
  title: string
  content: string
}

function QuestionAnswer(props: Props) {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')
  const [, setRotateState] = useState('accordion__icon')

  const content = useRef<HTMLDivElement | null>(null)

  const toggleAccordion = useCallback(() => {
    setActiveState(setActive === '' ? 'active' : '')
    setHeightState(
      setActive === 'active' ? '0px' : `${content?.current?.scrollHeight}px`
    )
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
    )
  }, [content, setActive])

  return (
    <div className="accordion__section">
      <button
        className={`accordion ${setActive} accordion__title`}
        onClick={toggleAccordion}
      >
        <p>{props.title}</p>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  )
}

export default QuestionAnswer
