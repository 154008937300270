function index() {
  return (
    <>
      <section className="container sustentabilidade">
        <div className="banner__sustentabilidade">
          <h2>Sustentabilidade na Decathlon</h2>
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/banner-sustentabilidade.png"
            alt="logo-decathlon"
          />
        </div>
        <div className="content__sustentabilidade">
          <p>
            O Decathlon Circula se junta a outros projetos ESG que temos na
            Decathlon Brasil, em prol da preservação do nosso planeta. Por isso,
            acreditamos que reusar é uma das soluções necessárias para que as
            próximas gerações não tenham que lidar com grandes impactos
            ambientais.
          </p>
          <p>
            Ao fazer uso de um produto usado, você estará participando de um
            movimento que se chama “economia circular”, que repensa na produção
            e comercialização dos produtos, em que se incentiva o uso de um
            produto por mais tempo, a fim de aumentar a sua vida útil,
            diminuindo assim descartes excessivos. Desta forma, este tipo de
            ação tem como objetivo reverter os danos ambientais como aquecimento
            global e a poluição.
          </p>
          <p>
            Reusar é dar uma segunda chance, evitar desperdícios e olhar para o
            planeta com mais cuidado. Venha fazer parte do futuro, junte-se a
            este movimento :)
          </p>
          <a
            href="https://www.decathlon.com.br/institucional/sustentabilidade-brasil"
            className="btn__sustentabilidade"
          >
            Saiba mais
          </a>
        </div>
      </section>
    </>
  )
}

export default index
