import { useState } from 'react'

import './style.scss'

import ComoComprar from './ComoComprar'
import ModalWhatsApp from '../ModalWhatsApp'
import PerguntasRespostas from './PerguntasRespostas'
import Regulamento from './Regulamento'
import PontoColeta from './PontoColeta'
import Sustentabilidade from './Sustentabilidade'
import Parceria from './Parceria'
import ContainerEasy from './ContainerEasy'

function Circula() {
  const [isModalWhatsAppVisible, setIsModalWhatsAppVisible] =
    useState<boolean>(false)

  function handleLinkWhatsApp(): void {
    if (window.screen.width > 991) {
      setIsModalWhatsAppVisible(true)
    } else {
      const url =
        'https://api.whatsapp.com/send/?phone=5511947312606&text=Ol%C3%81%20%21,%20gostaria de falar com um especialista do esporte.'

      window.open(url, '_blank')
    }
  }

  return (
    <>
      <main className="main-circula">
        <ContainerEasy />
        <ComoComprar />
        <section className="container produtos-aceitos">
          <div className="banner__produtos-aceitos">
            <h2>Produtos Aceitos</h2>
            <p>
              Equipamentos esportivos em boas condições e que tenham um valor de
              compra de um novo acima de R$ 200, e para bicicletas de adulto,
              esse valor é de R$ 2.000.
            </p>
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/image-content-1.png"
              alt="logo-decathlon"
            />
          </div>
          <div className="content__produtos-aceitos">
            <div className="details-content">
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>
                  Equipamentos de Montanhismo (saco de dormir, barraca,
                  fogareiro, cadeira e botas)
                </p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>Bolsas e Mochilas</p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>Óculos esportivos</p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>GPS</p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>
                  Bicicletas, equipamentos, peças e acessórios de ciclismo
                  (bagageiros, bolsas, rodas, etc)
                </p>
              </div>
              {/* <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>Capacetes</p>
              </div> */}
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>
                  Equipamentos de Academia/Fitness (halteres, wall ball, saco de
                  areia, bolas de equilíbrio)
                </p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>Patins e patinetes</p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>Raquetes</p>
              </div>
              <div className="wrapper-text">
                <img
                  src="https://decathlonpro.vteximg.com.br/arquivos/arrow-right-circleindicator.png"
                  alt="arrow-right-indicator"
                />
                <p>Tênis de corrida ou caminhada</p>
              </div>
            </div>
            <p className="contact-link">
              Não encontrou um produto?
              <button
                className="link__produtos-aceitos"
                onClick={() => handleLinkWhatsApp()}
              >
                Entre em contato
              </button>{' '}
              pelo WhatsApp da Decathlon.
            </p>
          </div>
        </section>
        <PontoColeta />
        <Sustentabilidade />
        <Parceria />
        <PerguntasRespostas />
        <Regulamento />
        {isModalWhatsAppVisible ? (
          <ModalWhatsApp hideModal={setIsModalWhatsAppVisible} />
        ) : null}
      </main>
    </>
  )
}

export default Circula
