import QuestionAnswer from '../../QuestionAnswer/QuestionAnswer'

function index() {
  return (
    <>
      <section id="duvidas" className="container questions">
        <h2>Ficou com alguma dúvida?</h2>
        <QuestionAnswer
          title="O que é Decathlon Circula?"
          content="É o programa que oferecemos aos nossos clientes uma maneira mais sustentável e acessível
          na compra e venda de equipamentos e itens usados. Todos os produtos vendidos dentro deste
          programa são devidamente
          identificados em suas páginas com todas as especificações e particularidades da peça."
        />

        <QuestionAnswer
          title="O que é possível vender?"
          content="Bicicletas adultas que tenham um valor de compra de um novo
          acima de R$2.000,00 e demais equipamentos acima de R$200,00.
          Consulte <a href='https://www.decathlon.com.br/servicos/decathlon-circula'>aqui</a> mais
          informações."
        />

        <QuestionAnswer
          title="Como faço para cadastrar um produto?"
          content="Você separa os itens que deseja vender, leva
          até o ponto de coleta que nossos atendentes farão o
          seu cadastro. Você deixa seus itens que o time faz a triagem,
          fotógrafa, cadastra, pública e cuida do envio das suas peças
          vendidas, você só aprova os preços e acompanha o processo."
        />

        <QuestionAnswer
          title="Meu produto foi vendido, qual o prazo para recebimento?"
          content="Após a venda realizada, o valor pago no produto é repassado,
                        retirado a comissão, após o 7ª dia da entrega ao comprador.
                        Pode ser feita a transferência para a conta bancária cadastrada."
        />
        <QuestionAnswer
          title="Existe taxa para venda?"
          content="Para transferência de valores é cobrada uma taxa de
                        40% do valor do produto."
        />
      </section>
    </>
  )
}

export default index
